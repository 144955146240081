// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "@shoothill/core";

// Custom
import { IEViewModel } from "../IE.ViewModel";
import { PackagesViewModel } from "../PackagesViewModel";
import { formatCurrencyNoSign } from "Utils/Format";

// Styling & Images
import { SubsGridView } from "./IEGrid.Styles";
import { AppUrls } from "AppUrls";
import { IEGridModel } from "../IEmodels/IEGridModel";
import { IEGridItemViewModel } from "../IEmodels/IEGridItemViewModel";
import { formatCreditDebitClass, formatCreditDebitReverseClass } from "Utils/Utils";

interface ITableProps {
    viewModel: IEViewModel; //CommercialViewModel;
    packagesViewModel: PackagesViewModel;
    parentId: string;
    chosenId(saved: string): void;
    IEid: string;
}

export const IEGridViewVariants: React.FunctionComponent<ITableProps> = (props) => {
    const router = useRouter();
    const { history } = useRouter();
    const { viewModel, packagesViewModel, chosenId, parentId } = props;

    const formatDebit = (val: number) => {
        return val < 0 ? " debit" : "";
    };

    return useObserver(() => (
        <>
            {viewModel.filterIEByVariant.length > 0 && (
                <>
                    <span style={{ letterSpacing: "-0.36px", fontSize: "18px", marginLeft: "35px" }}>Variants</span>
                    <SubsGridView>
                        {viewModel.filterIEByVariant.map((obj: IEGridModel, index: number) => (
                            <>
                                <li className="ie" key={"ie_" + index} style={{ fontStyle: "italic", color: "#6C93A6" }}>
                                    <div style={{ paddingLeft: "25px" }}>{obj.name}</div>
                                    <div className="right">{formatCurrencyNoSign(obj.income)}</div>
                                    <div className="right">{formatCurrencyNoSign(obj.estimateCost)}</div>
                                    <div className="right">{obj.margin}</div>
                                    <div className={"right" + formatDebit(obj.committedCost)}>{formatCurrencyNoSign(obj.committedCost)}</div>

                                    {!IEGridItemViewModel.Instance.isCentral && <div className="right">{formatCurrencyNoSign(obj.futureSpend)}</div>}
                                    <div className="right">{formatCurrencyNoSign(obj.totalExpectedSpend)}</div>
                                    <div className={"right" + formatCreditDebitClass(obj.variance)}>{formatCurrencyNoSign(obj.variance)}</div>
                                    <div className={"right" + formatCreditDebitReverseClass(obj.roValue)}>{formatCurrencyNoSign(obj.roValue)}</div>
                                    <div
                                        onClick={() => {
                                            if (IEGridItemViewModel.Instance.isCentral) {
                                                history.push(AppUrls.Client.Central.View.replace(":ieid", props.IEid) + "#var");
                                            } else {
                                                history.push(AppUrls.Client.Project.IE.replace(":ieid", props.IEid) + "#var");
                                            }
                                        }}
                                        style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "space-around" }}
                                    ></div>
                                </li>
                            </>
                        ))}
                    </SubsGridView>
                </>
            )}
        </>
    ));
};
