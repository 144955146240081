import { action, observable, computed } from "mobx";
import { ModelBase } from "@shoothill/core";
import { PackageCategoriesResult } from "./PackageCategoriesResult";

export class POFilterParamsModel extends ModelBase<POFilterParamsModel, POFilterParamsModelDTO> {
    @observable
    public includeDeleted: boolean = false;

    @observable
    public isRejected: boolean = false;

    @observable
    public categories: number[] | null = [];

    @observable
    public subCategories: number[] | null = [];

    @observable
    public statuses: string[] | null = [];

    @observable
    public orderTypes: string[] | null = []; //[100, 200]

    @observable
    public cells: number[] | null = []; //[1, 2, 3]

    @observable
    public raisedByIds: string[] | null = [];

    @observable
    public projects: string[] | null = [];

    @observable
    public supplierIds: string[] | null = [];

    @observable
    public searchText: string = "";

    @observable
    public pageNumber: number = 1;

    @observable
    public pageSize: number = 50;

    @observable
    public sortDirection: string = "DESC";

    @observable
    public sortBy: string = "createdDate";

    @observable
    public totalCount: number = 0;

    @computed
    public get pageCount() {
        return Math.ceil(this.totalCount / this.pageSize);
    }

    fromDto(model: POFilterParamsModelDTO): void {
        this.categories = model.categories;
        this.subCategories = model.subCategories;
        this.searchText = model.searchText;
    }

    toDto(): POFilterParamsModelDTO {
        let dto: POFilterParamsModelDTO = {
            includeDeleted: this.includeDeleted,
            isRejected: false,
            categories: this.categories,
            subCategories: this.subCategories,
            statuses: this.statuses,
            orderTypes: this.orderTypes,
            cells: this.cells,
            searchText: this.searchText,
            raisedByIds: this.raisedByIds,
            projects: this.projects,
            supplierIds: this.supplierIds,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            sortDirection: this.sortDirection.toUpperCase(),
            sortBy: this.sortBy,
            initialLoad: true,
            isCSV: false,
        };
        return dto;
    }

    @action
    public setCategoriesAndSubFilters = (result: PackageCategoriesResult) => {
        if (result !== null && result.categories !== null && result.categories.length > 0) {
            this.categories = result.categories.map((a) => a.id);
        }

        if (result !== null && result.subCategories !== null && result.subCategories.length > 0) {
            this.subCategories = result.subCategories.map((a) => a.id);
        }
    };

    @action
    public reset = () => {
        this.categories = [];
        this.subCategories = [];
        this.statuses = [];
        this.orderTypes = [];
        this.cells = [];
        this.searchText = "";
        this.pageNumber = 1;
        this.pageSize = 50;
        this.totalCount = 0;
        this.sortBy = "createdDate";
        this.sortDirection = "DESC";
        this.raisedByIds = [];
        this.projects = [];
        this.supplierIds = [];
    };
}

export interface POFilterParamsModelDTO {
    includeDeleted: boolean;
    isRejected: boolean;
    categories: number[] | null;
    subCategories: number[] | null;
    statuses: string[] | null;
    orderTypes: string[] | null;
    cells: number[] | null;
    searchText: string;
    raisedByIds: string[] | null;
    projects: string[] | null;
    supplierIds: string[] | null;
    pageNumber: number;
    pageSize: number;
    sortDirection: string;
    sortBy: string;
    initialLoad: boolean;
    isCSV: boolean;
}
