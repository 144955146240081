// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";

// Custom
import { IEGridSubcategoryModel } from "../../IEmodels";
import { CommercialViewModel } from "../../Commercial.ViewModel";
import { PackagesViewModel } from "../../PackagesViewModel";
import { formatCurrencyForGrid, formatCurrencyNoSign } from "Utils/Format";

// Styling & Images
import { ToggleButton } from "../IEGrid.Styles";
import { RowDash } from "Views/Project/CustomComponents";
import { formatCreditDebitClass, formatCreditDebitReverseClass } from "Utils/Utils";
import { IEGridItemViewModel } from "../../IEmodels/IEGridItemViewModel";

interface ITableProps {
    itemModel: IEGridSubcategoryModel;
    iEId: string;
    rowType: string;
    categoryIndex: number;
    rowIndex: number;
    categoryGuid: string;
    rowEnable(parentIndex: number, index: number): void;
    currentActiveCatIndex: number;
    currentActiveSubCatIndex: number;
}

export const IESubcat: React.FunctionComponent<ITableProps> = (props) => {
    const { itemModel, rowType, rowEnable, rowIndex, categoryIndex, currentActiveCatIndex, currentActiveSubCatIndex } = props;
    const packagesViewModel = PackagesViewModel.Instance;
    const commercialViewModel = CommercialViewModel.Instance;

    const formatDebit = (val: number) => {
        return commercialViewModel.formatDebit(val);
    };

    const handleName = () => {
        return packagesViewModel.getSubcategoryName(itemModel.sourceId);
    };

    return useObserver(() => (
        <>
            <li className={rowType} key={"subs_" + rowIndex}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ color: `${currentActiveCatIndex === categoryIndex && currentActiveSubCatIndex === rowIndex ? "#0095DA" : ""}`, paddingLeft: "10px" }}>
                        <RowDash style={{ display: "inline-block", marginRight: "10px" }} />
                        <span className="ie-row-name ie-subcat-name" title={handleName()}>
                            {handleName()}
                        </span>
                    </div>
                    {currentActiveCatIndex === categoryIndex && currentActiveSubCatIndex === rowIndex ? (
                        <ToggleButton>
                            <span
                                className={"open"}
                                onClick={() => {
                                    rowEnable(-1, -1);
                                }}
                            >
                                &minus;
                            </span>
                        </ToggleButton>
                    ) : (
                        <ToggleButton>
                            <span
                                className={"close"}
                                onClick={() => {
                                    rowEnable(categoryIndex, rowIndex);
                                }}
                            >
                                &#43;
                            </span>
                        </ToggleButton>
                    )}
                </div>
                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.income))}</div>
                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.estimateCost))}</div>
                <div className="right">{itemModel.margin}</div>
                <div className={"right" + formatDebit(itemModel.committedCost)}>{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.committedCost))}</div>
                {!IEGridItemViewModel.Instance.isCentral && <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.futureSpend))}</div>}
                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.totalExpectedSpend))}</div>
                <div className={"right" + formatCreditDebitClass(itemModel.variance)}>{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.variance))}</div>
                <div className={"right" + formatCreditDebitReverseClass(itemModel.roValue)}>{formatCurrencyForGrid(formatCurrencyNoSign(itemModel.roValue))}</div>
                <div className="right" style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}></div>
            </li>
        </>
    ));
};
