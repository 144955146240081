import { InvoicingListFilterParamsBaseModel, InvoicingListFilterParamsBaseModelDTO } from "./InvoiceListFilterParamsBaseModel";

export class InvoicingListFilterParamsModel extends InvoicingListFilterParamsBaseModel {
    fromDto(model: InvoicingListFilterParamsBaseModelDTO): void {
        this.searchText = model.searchText;
    }

    toDto(): InvoicingListFilterParamsBaseModelDTO {
        let dto: InvoicingListFilterParamsBaseModelDTO = {
            includeDeleted: this.includeDeleted,
            statuses: this.statuses,
            projects: this.projects,
            suppliers: this.suppliers,
            searchText: this.searchText,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            sortDirection: this.sortDirection.toUpperCase(),
            sortBy: this.sortBy,
            initialLoad: true,
            isCSV: false,
        };
        return dto;
    }
}

// export interface InvoicingListFilterParamsBaseModelDTO  {

// }
